import styles from "./WatchDocsPage.module.scss";
import {
  Link,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  DOCS_INFO,
  DocsEnum,
  ROUTERS_NAMES
} from "shared/consts";
import { ReactComponent as ArrowIcon } from "shared/assets/images/arrow.svg"
import { ReactComponent as DownloadIcon } from "shared/assets/images/download.svg"
import {
  useEffect,
  useRef,
  useState
} from "react";
import * as docx from "docx-preview";
import { useScrollDelta } from "shared/hooks";
import { motion } from "framer-motion";

export default function WatchDocsPage() {
  const params = useParams<{ docName: DocsEnum }>()
  const navigate = useNavigate()

  const [contentRef] = useScrollDelta<HTMLDivElement>(8)
  const containerRef = useRef<HTMLDivElement>(null)

  const [blob, setBlob] = useState<Blob>()

  const IS_DETAILS = params.docName === DocsEnum.DETAILS

  const handleDownload = () => {
    if (blob) {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${params.docName}.docx`;
      downloadLink.click();
    }
  }

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/docs/${params.docName}.docx`).then((res) => res.blob()).then((res) => {
      setBlob(res)
      if (containerRef.current) {
        docx.renderAsync(res, containerRef.current).catch((err) => {
          console.error(err)
          navigate(ROUTERS_NAMES.notFound)
        });
      }
    }).catch(err => {
      console.error(err)
    });
  }, [params.docName, navigate]);
  
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <div className={styles.watchdocs}>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1 } }}
            className={styles.back}
          >
            <Link to={ROUTERS_NAMES.home}><ArrowIcon/>Back</Link>
          </motion.div>

          {IS_DETAILS && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 2 } }}
              className={styles.download}
              onClick={handleDownload}
            >
              <DownloadIcon/>
            </motion.button>
          )}
        </div>

        <motion.h2
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: IS_DETAILS ? 3 : 2 }
          }}
          className={styles.title}
        >
          {params.docName ? DOCS_INFO[params.docName] : ""}
        </motion.h2>
      </div>

      <div className={styles.content} ref={contentRef}>
        <motion.div
          className={styles.document}
          ref={containerRef}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: IS_DETAILS ? 4 : 3, duration: 0.5 } }}
        />
      </div>
    </div>
  )
}
