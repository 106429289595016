import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState
} from 'react'

interface IModalContext {
  modalOpened: boolean,
  setModalOpened: Dispatch<SetStateAction<boolean>>
}

const DEFAULT_STATE: IModalContext = {
  modalOpened: false,
  setModalOpened: () => {}
}

export const ModalContext = createContext<IModalContext>(DEFAULT_STATE)

export default function ModalContextProvider({ children }: PropsWithChildren) {
  const [modalOpened, setModalOpened] = useState(DEFAULT_STATE.modalOpened)

  return (
    <ModalContext.Provider value={{ modalOpened, setModalOpened }}>
      {children}
    </ModalContext.Provider>
  )
}
