import {
  AnimatePresence,
  motion
} from "framer-motion"
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import { Link } from "react-router-dom"

import adv1 from "shared/assets/images/adv1.svg"
import adv2 from "shared/assets/images/adv2.svg"
import adv3 from "shared/assets/images/adv3.svg"
import adv4 from "shared/assets/images/adv4.svg"
import doc from "shared/assets/images/doc.svg"
import logo from "shared/assets/images/logo-new.svg"
import mouse from "shared/assets/images/mouse.svg"

import {
  ModalContext,
  MusicContext
} from "shared/contexts"
import styles from "./HomePage.module.scss"
import {
  DEFAULT_MOTION,
  DOCS_INFO,
  ROUTERS_NAMES
} from "shared/consts";
import { useScrollDelta } from "shared/hooks";

enum StepsEnum {
  "INTRO" = 1,
  "DESCRIPTION",
  "EXPERTISE",
  "DOCS",
}

export default function HomePage() {
  const homepageRef = useRef<HTMLDivElement>(null)
  const contentArea = useRef<HTMLDivElement>(null)
  const stepChangingRef = useRef(false)

  // Contexts
  const { modalOpened, setModalOpened }: any = useContext(ModalContext)
  const { musicPlaying, toggleMusic } = useContext(MusicContext)

  // mobile
  const [isMobile, setIsMobile] = useState(false)

  // direction
  const [direction, setDirection] = useState("down")

  // changing steps
  const [step, setStep] = useState(StepsEnum.INTRO)

  // step 2 scrolling
  const [part2ElemActive, setPart2ElemActive] = useState(1)

  const handleWheelList = (list: HTMLUListElement, e: WheelEvent) => {
    let scrollPercentage = (list.scrollTop / (list.scrollHeight - list.clientHeight)) * 100
    const elemActive = Math.round(1 + (scrollPercentage / 85) * (5 - 1))
    setPart2ElemActive(elemActive)

    if (list.scrollTop === 0 && e.deltaY < 0) {
      stepBackward()
    } else if (list.scrollTop + list.clientHeight > list.scrollHeight - 1 && e.deltaY > 0) {
      stepForward()
    }
  }
  const [part2ListRef, setActive] = useScrollDelta<HTMLUListElement>(16, handleWheelList)

  // changing steps logic
  const stepForward = () => {
    if (!stepChangingRef.current) {
      setStep(prevState => {
        if (prevState === StepsEnum.DOCS) {
          return prevState
        } else {
          return prevState + 1
        }
      })

      stepChangingRef.current = true

      setTimeout(() => {
        stepChangingRef.current = false
      }, 1600)
    }
  }

  const stepBackward = () => {
    if (!stepChangingRef.current) {
      setStep(prevState => {
        if (prevState === StepsEnum.INTRO) {
          return prevState
        } else {
          return prevState - 1
        }
      })

      stepChangingRef.current = true

      setTimeout(() => {
        stepChangingRef.current = false
      }, 1600)
    }
  }

  const startIntro = useCallback(() => {
    if (step === StepsEnum.INTRO) {
      stepForward()
      if (!musicPlaying) toggleMusic()
    }
  }, [musicPlaying, step, toggleMusic])

  const getButtonWidth = () => {
    if (contentArea.current && !isMobile) {
      if (step === StepsEnum.DESCRIPTION) {
        return {
          width: `${contentArea.current.clientWidth}px`
        }
      }

      if (step === StepsEnum.EXPERTISE || step === StepsEnum.DOCS) {
        return { width: "100vw" }
      }
    }
  }

  useEffect(() => {
    if (isMobile || modalOpened) return

    const wheelStepChange = (e: WheelEvent) => {
      const nextDirection = e.deltaY > 0 ? "down" : "up";
      setDirection(prevDirection => {
        if (prevDirection === nextDirection && step !== StepsEnum.DESCRIPTION) {
          (nextDirection === "down" ? stepForward : stepBackward)()
        }

        return nextDirection
      })
    }

    window.addEventListener("wheel", wheelStepChange)

    return () => {
      window.removeEventListener("wheel", wheelStepChange)
    }
  }, [modalOpened, isMobile, step])

  // Enter on mouse
  useEffect(() => {
    const enterIntro = (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        startIntro()
      }
    }

    if (!isMobile) {
      window.addEventListener("keyup", enterIntro)
      window.addEventListener("mousedown", startIntro)

      return () => {
        window.removeEventListener("keyup", enterIntro)
        window.removeEventListener("mousedown", startIntro)
      }
    }
  }, [isMobile, startIntro])

  useEffect(() => {
    setActive(!(isMobile || step !== StepsEnum.DESCRIPTION || modalOpened))
  }, [step, modalOpened, isMobile, setActive])

  useEffect(() => {
    const mobileWidthListener = () => {
      setIsMobile(window.innerWidth <= 900)
    }
    mobileWidthListener()
    window.addEventListener("resize", mobileWidthListener)

    return () => {
      window.removeEventListener("resize", mobileWidthListener)
    }
  }, [])

  useEffect(() => {
    Object.entries({
      1000: styles.loader1,
      2000: styles.loader2,
      3000: styles.loader3,
      4000: styles.loader4,
      5000: styles.loader5,
    }).forEach(([key, value]) => {
      setTimeout(() => {
        homepageRef?.current?.classList?.add(value)
      }, Number(key))
    })

    return () => {
      setStep(StepsEnum.INTRO)
    }
  }, [])

  return (
    <div className={styles.homepage} ref={homepageRef}>
      <button
        onClick={() => {
          setModalOpened(true)
        }}
        style={getButtonWidth()}
        className={`${styles.button} ${step !== StepsEnum.INTRO ? styles.active : ""}`}
      >
        Get in touch
      </button>
      <div className={styles.title1}>
        <h2 style={step > StepsEnum.INTRO ? { opacity: .2 } : undefined} className={styles.title1Text}>
          From concept
        </h2>
      </div>
      <Link to={ROUTERS_NAMES.home} className={styles.logo}>
        <img
          draggable="false"
          className={styles.logoImage}
          src={logo}
          alt=""
        />
      </Link>
      <div className={styles.title2}>
        <h2 style={step > StepsEnum.INTRO ? { opacity: .2 } : undefined} className={styles.title2Text}>
          To reality
        </h2>
      </div>

      {isMobile && (
        <p className={styles.contentHeadersDescription}>
          Empowering your brand to thrive globally through strategic digital marketing and seamless
          multi-channel engagement
        </p>
      )}

      {!isMobile && (
        <div
          className={styles.contentHeaders + " " + styles[step === StepsEnum.EXPERTISE || step === StepsEnum.DOCS ? "contentHeadersMinify" : ""]}
        >
          <div className={styles.contentHeadersInner}>
            <AnimatePresence mode="wait">
              {step === StepsEnum.INTRO &&
                <motion.p
                  key="contentHeader1"
                  {...DEFAULT_MOTION}
                  className={styles.contentHeadersDescription}
                >
                  Empowering your brand to thrive globally through strategic digital marketing and
                  seamless multi-channel engagement
                </motion.p>
              }
              {step === StepsEnum.DESCRIPTION &&
                <motion.h3
                  key="contentHeader2"
                  {...DEFAULT_MOTION}
                  className={styles.contentHeadersStep2}
                >
                  WE
                </motion.h3>
              }
              {step === StepsEnum.EXPERTISE &&
                <motion.h3
                  key="contentHeader3"
                  {...DEFAULT_MOTION}
                  className={styles.contentHeadersStep3}
                >
                  Why choose RSG as your partner?
                </motion.h3>
              }
              {step === StepsEnum.DOCS &&
                <motion.h3
                  key="contentHeader4"
                  {...DEFAULT_MOTION}
                  className={styles.contentHeadersStep3}
                >
                  Documents
                </motion.h3>
              }
            </AnimatePresence>
          </div>
        </div>
      )}

      {!isMobile && (
        <div ref={contentArea} className={styles.contentArea + " " + styles[`step${step}`]}>
          <div className={styles.contentAreaInner}>
            <AnimatePresence mode="wait">
              {step === StepsEnum.INTRO &&
                <motion.div
                  key="step1_content"
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: .6 }}
                >
                  <div className={styles.contentAreaMouseWrapper}>
                    <img className={styles.contentAreaMouse} draggable="false" src={mouse} alt=""/>
                  </div>
                </motion.div>}

              {step === StepsEnum.DESCRIPTION &&
                <motion.div
                  className={styles.contentAreaListWrapper}
                  key="step2_content"
                  initial={{ opacity: 0, y: direction === "down" ? 50 : -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: direction === "down" ? -50 : 50 }}
                  transition={{ duration: .6 }}
                >
                  <ul ref={part2ListRef} className={styles.contentAreaList}>
                    <li style={part2ElemActive === 1 ? { color: "#fff" } : {}}>
                      offer multifaceted strategies aimed to target, reach out to, and build relationships with
                      customers online, across a broad range of channels
                    </li>
                    <li style={part2ElemActive === 2 ? { color: "#fff" } : {}}>
                      start from defining your Target Audience and what they"re looking for
                    </li>
                    <li style={part2ElemActive === 3 ? { color: "#fff" } : {}}>
                      help to choose the right platform and the most effective ad format to reach your Target Audience
                    </li>
                    <li style={part2ElemActive === 4 ? { color: "#fff" } : {}}>use various Ad
                      Placement Techniques to maximize the effectiveness of your campaigns
                    </li>
                    <li style={part2ElemActive === 5 ? { color: "#fff" } : {}}>
                      provide digital marketing services and IT consulting for the placement of advertising and
                      information materials to increase your brand awareness and improve user acquisition by
                      establishing synergy between various platforms all over the world
                    </li>
                  </ul>
                </motion.div>
              }
              {step === StepsEnum.EXPERTISE &&
                <motion.div
                  className={styles.contentAreaExpertise}
                  key="step3_content"
                  initial={{ opacity: 0, y: direction === "down" ? 100 : -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: direction === "down" ? -50 : 50 }}
                  transition={{ duration: .6 }}
                >
                  <ul className={styles.contentAreaExpertiseList}>
                    <li><img src={adv1} alt=""/><span>Industry expertise</span></li>
                    <li>
                      <img src={adv2} alt=""/><span>Comprehensive Digital <br/> Marketing Solutions</span>
                    </li>
                    <li><img src={adv3} alt=""/><span>Results-Driven Approach</span></li>
                    <li><img src={adv4} alt=""/><span>Exceptional Client Service</span></li>
                  </ul>
                </motion.div>
              }
              {step === StepsEnum.DOCS &&
                <motion.div
                  className={styles.contentAreaDocs}
                  key="step4_content"
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ duration: .6 }}
                >
                  <ul className={styles.contentAreaDocsList}>
                    {Object.entries(DOCS_INFO).map(([link, title]) => (
                      <li key={link}>
                        <Link to={`${ROUTERS_NAMES.watchDocs}/${link}`}>
                          <img src={doc} alt=""/>
                          <span>{title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </motion.div>
              }
            </AnimatePresence>
          </div>
        </div>
      )}

      {isMobile && (
        <div className={styles.contentArea + " " + styles[`step${step}`]}>
          <div className={styles.contentAreaInner}>
            <div className={styles.contentAreaMouseWrapper}>
              <img className={styles.contentAreaMouse} draggable="false" src={mouse} alt=""/>
            </div>

            <div className={styles.contentAreaListWrapper}>
              <h3 className={styles.contentHeadersStep2}>WE</h3>
              <ul className={styles.contentAreaList}>
                <li>
                  offer multifaceted strategies aimed to target, reach out to, and build relationships with customers
                  online, across a broad range of channels
                </li>
                <li>start from defining your Target Audience and what they're looking for</li>
                <li>
                  help to choose the right platform and the most effective ad format to reach your Target Audience
                </li>
                <li>
                  use various Ad Placement Techniques to maximize the effectiveness of your campaigns
                </li>
                <li>
                  provide digital marketing services and IT consulting for the placement of advertising and
                  information materials to increase your brand awareness and improve user acquisition by establishing
                  synergy between various platforms all over the world
                </li>
              </ul>
            </div>
            <h3 className={styles.contentHeadersStep3}>Why choose RSG as your partner?</h3>
            <div className={styles.contentAreaExpertise} key="step3_content">
              <ul className={styles.contentAreaExpertiseList}>
                <li><img src={adv1} alt=""/><span>Industry expertise</span></li>
                <li><img src={adv2} alt=""/><span>Comprehensive Digital <br/> Marketing Solutions</span>
                </li>
                <li><img src={adv3} alt=""/><span>Results-Driven Approach</span></li>
                <li><img src={adv4} alt=""/><span>Exceptional Client Service</span></li>
              </ul>
            </div>

            <h3 className={styles.contentHeadersStep3}>Documents</h3>
            <ul className={styles.contentAreaDocsList}>
              {Object.entries(DOCS_INFO).map(([link, title]) => (
                <li key={link}>
                  <Link to={`${ROUTERS_NAMES.watchDocs}/${link}`}>
                    <img src={doc} alt=""/>
                    <span>{title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div onClick={toggleMusic} className={styles.footerCompany}>
        {musicPlaying && <div className={styles.footerCompanyMusic}/>}
        <p className={styles.footerCompanyText}>RS GROUP L.L.C-FZ</p>
      </div>
      <div className={styles.footerAddress}>
        <p className={styles.footerAddressText}>
          Meydan Grandstand, 6th floor, Meydan Road, Nad Al Sheba, Dubai, U.A.E
        </p>
      </div>
      <button
        onClick={() => {
          setStep(StepsEnum.DOCS)
        }}
        className={styles.footerDocs}
      >
        <p className={styles.footerDocsText}>Documents</p>
      </button>
      <a href="mailto:info@rsgroup.company" className={styles.footerEmail}>
        <p className={styles.footerEmailText}>info@rsgroup.company</p>
      </a>
    </div>
  )
}
