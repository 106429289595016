import { useEffect, useState } from 'react'
import styles from './Cursor.module.scss'

export default function Cursor() {
  const [coords, setCoords] = useState({ left: 0, top: 0, transform: "scale(0)" }); // кладем в стейт данные курсора
  
  useEffect(() => {

    const mouseListener = (e: MouseEvent) => {
      setCoords( {left: e.pageX, top: e.pageY, transform: "scale(1)"});
    }

    document.addEventListener('mousemove', mouseListener);

    return () => {
      document.removeEventListener('mousemove', mouseListener);
    }

  }, [])

  return (
    <>
    <div style={coords} className={styles.cursor}>
      <div className={styles.cursorInner}></div>
    </div>
    </>
  )
}
