// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../shared/assets/images/noise.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_noise__9iKSH {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  mix-blend-mode: multiply;
  height: 100vh;
  min-height: 800px;
  opacity: 0.15;
  pointer-events: none;
  z-index: 10000;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Layout.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,wBAAA;EACA,aAAA;EACA,iBAAA;EACA,aAAA;EACA,oBAAA;EACA,cAAA;EACA,yDAAA;EACA,sBAAA;AACF","sourcesContent":[".noise {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  mix-blend-mode: multiply;\n  height: 100vh;\n  min-height: 800px;\n  opacity: .15;\n  pointer-events: none;\n  z-index: 10000;\n  background-image: url(../shared/assets/images/noise.gif);\n  background-size: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noise": `Layout_noise__9iKSH`
};
export default ___CSS_LOADER_EXPORT___;
