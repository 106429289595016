import {
  useEffect,
  useRef,
  useState
} from "react";

type UseScrollDeltaProps<T> = [React.RefObject<T>, (v: boolean) => void]

export const useScrollDelta = <T extends HTMLElement>(slowdownLevel?: number, callback?: (ref: T, e: WheelEvent) => void): UseScrollDeltaProps<T> => {
  const targetRef = useRef<T>(null)
  const [isActive, setActive] = useState(true)
  
  useEffect(() => {
    if (isActive) {
      const wheelListener = (e: WheelEvent) => {
        if (targetRef.current) {
          targetRef.current.scrollTop += e.deltaY / (slowdownLevel || 1)
          
          callback?.(targetRef.current, e)
        }
      }
      window.addEventListener("wheel", wheelListener)

      return () => {
        window.removeEventListener("wheel", wheelListener)
      }
    }
  }, [isActive, slowdownLevel, callback])
  
  return [targetRef, setActive]
}
