// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./shared/assets/fonts/stylesheet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --black: #171717;
  --white: #FFF;
  --white1: #D9D9D9;
  --white20: #FFFFFF33;
  --mainColor: #87D606;
}

* {
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: "Benzin", sans-serif;
  font-weight: normal;
  font-style: normal;
  overflow: hidden;
  background-color: var(--black);
  cursor: none;
  -webkit-user-select: none;
          user-select: none;
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
}

a {
  cursor: none;
  text-decoration: none;
}

::selection {
  background: var(--mainColor);
  color: var(--black);
}`, "",{"version":3,"sources":["webpack://./src/shared/styles/_consts.scss","webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,oBAAA;ACEF;;AAJA;EACE,aAAA;EACA,sBAAA;AAOF;;AAJA;EACE,iCAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,8BAAA;EACA,YAAA;EACA,yBAAA;UAAA,iBAAA;EACA,UAAA;EACA,SAAA;EACA,yBAAA;AAOF;;AAJA;EACE,YAAA;EACA,qBAAA;AAOF;;AAJA;EACE,4BAAA;EACA,mBAAA;AAOF","sourcesContent":[":root {\n  --black: #171717;\n  --white: #FFF;\n  --white1: #D9D9D9;\n  --white20: #FFFFFF33;\n  --mainColor: #87D606;\n}\n\n\n","@import 'shared/styles/consts';\n@import \"shared/assets/fonts/stylesheet.css\";\n\n* {\n  outline: none;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: \"Benzin\", sans-serif;\n  font-weight: normal;\n  font-style: normal;\n  overflow: hidden;\n  background-color: var(--black);\n  cursor: none;\n  user-select: none;\n  padding: 0;\n  margin: 0;\n  overscroll-behavior: none\n}\n\na {\n  cursor: none;\n  text-decoration: none;\n}\n\n::selection {\n  background: var(--mainColor);\n  color: var(--black);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
