// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage_errorpage__BjGfv {
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.ErrorPage_headings__PJMmi {
  color: var(--white);
}

.ErrorPage_title__SEONk {
  margin: clamp(-50px, -5vw, 0px) 0;
  font-size: clamp(50px, 25vw, 200px);
}

.ErrorPage_desc__11uR0 {
  margin: 0;
  font-size: clamp(13px, 4.3vw, 33px);
}

.ErrorPage_button__3Rttw {
  position: absolute;
  color: var(--black);
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 1;
  background-color: var(--mainColor);
  font-size: 20px;
  font-family: Benzin, "sans-serif";
  font-weight: 800;
  border: none;
  padding: 20px 50px;
  cursor: none;
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  transition: 0.5s ease all;
}
.ErrorPage_button__3Rttw:active {
  transform: scale(1.05);
  background-color: var(--white);
  box-shadow: -5px -5px 20px var(--white20);
}
.ErrorPage_button__3Rttw a {
  color: var(--black);
}

@media (hover: hover) {
  .ErrorPage_button__3Rttw:hover {
    background-color: var(--white);
  }
}
@media (max-width: 450px) {
  .ErrorPage_button__3Rttw {
    width: 100%;
    font-size: 15px;
    padding: 20px;
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/ErrorPage/ErrorPage.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,iCAAA;EACA,mCAAA;AACF;;AAEA;EACE,SAAA;EACA,mCAAA;AACF;;AAGA;EACE,kBAAA;EACA,mBAAA;EACA,MAAA;EACA,OAAA;EACA,UAAA;EACA,UAAA;EAGA,kCAAA;EACA,eAAA;EACA,iCAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,yBAAA;EACA,yBAAA;AAFF;AAGE;EACE,sBAAA;EACA,8BAAA;EACA,yCAAA;AADJ;AAGE;EACE,mBAAA;AADJ;;AAKA;EAEI;IACE,8BAAA;EAHJ;AACF;AAOA;EACE;IACE,WAAA;IACA,eAAA;IACA,aAAA;IACA,kBAAA;EALF;AACF","sourcesContent":[".errorpage {\n  color: var(--white);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n}\n\n.headings {\n  color: var(--white);\n}\n\n.title {\n  margin: clamp(-50px, -5vw, 0px) 0;\n  font-size: clamp(50px, 25vw, 200px);\n}\n\n.desc {\n  margin: 0;\n  font-size: clamp(13px, 4.3vw, 33px);\n  // display: none;\n}\n\n.button {\n  position: absolute;\n  color: var(--black);\n  top: 0;\n  left: 0;\n  z-index: 3;\n  opacity: 1;\n  // width: 200px;\n  // height: 80px;\n  background-color: var(--mainColor);\n  font-size: 20px;\n  font-family: Benzin, 'sans-serif';\n  font-weight: 800;\n  border: none;\n  padding: 20px 50px;\n  cursor: none;\n  outline: none;\n  box-shadow: none;\n  text-transform: uppercase;\n  transition: .5s ease all;\n  &:active {\n    transform: scale(1.05);\n    background-color: var(--white);\n    box-shadow: -5px -5px 20px var(--white20);\n  }\n  a {\n    color: var(--black);\n  }\n}\n\n@media(hover: hover) {\n  .button {\n    &:hover {\n      background-color: var(--white);\n    }\n  }\n}\n\n@media(max-width: 450px) {\n  .button {\n    width: 100%;\n    font-size: 15px;\n    padding: 20px;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorpage": `ErrorPage_errorpage__BjGfv`,
	"headings": `ErrorPage_headings__PJMmi`,
	"title": `ErrorPage_title__SEONk`,
	"desc": `ErrorPage_desc__11uR0`,
	"button": `ErrorPage_button__3Rttw`
};
export default ___CSS_LOADER_EXPORT___;
