// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cursor_cursor__98OV1 {
  display: none;
  position: absolute;
  background-color: var(--white);
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: 0.3s 0.3s ease transform;
  mix-blend-mode: difference;
  z-index: 1000;
  border-radius: 50%;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  pointer-events: none;
}

@media (hover: hover) {
  .Cursor_cursor__98OV1 {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Cursor/Cursor.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,oCAAA;EACA,0BAAA;EACA,aAAA;EACA,kBAAA;EACA,kCAAA;UAAA,0BAAA;EACA,oBAAA;AACF;;AAEA;EACE;IACE,cAAA;EACF;AACF","sourcesContent":[".cursor {\n  display: none;\n  position: absolute;\n  background-color: var(--white);\n  width: 12px;\n  height: 12px;\n  transform: scale(0);\n  transition: .3s .3s ease transform;\n  mix-blend-mode: difference;\n  z-index: 1000;\n  border-radius: 50%;\n  backdrop-filter: blur(2px);\n  pointer-events: none;\n}\n\n@media(hover: hover) {\n  .cursor {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursor": `Cursor_cursor__98OV1`
};
export default ___CSS_LOADER_EXPORT___;
