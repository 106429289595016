// base plugins
import styles from './Layout.module.scss'
import {
  useLocation,
  useOutlet,
} from 'react-router-dom'

// components
import Cursor from 'components/Cursor'
import Modal from 'components/Modal'
import {
  AnimatePresence,
  motion
} from "framer-motion";
import { DEFAULT_MOTION } from "shared/consts";

// ✦ code ✦

export default function Layout() {
  const outlet = useOutlet();

  return (
    <>
      <Cursor/>
      <div className={styles.noise}/>
      <AnimatePresence mode="wait">
        <motion.main key={useLocation().key} {...DEFAULT_MOTION}>{outlet}</motion.main>
      </AnimatePresence>
      <Modal/>
    </>
  )
}
