import styles from './ErrorPage.module.scss'
import { Link } from 'react-router-dom'
import { ROUTERS_NAMES } from "shared/consts";

import { motion } from "framer-motion";

export default function ErrorPage() {
  return (
    <div className={styles.errorpage}>
      <motion.div
        className={styles.button}
        initial={{opacity: 0, x: -100}}
        animate={{opacity: 1, x: 0}}
        transition={{delay: 1, duration: .3}}
      ><Link to={ROUTERS_NAMES.home}>Back to home</Link></motion.div>
      <Link to={ROUTERS_NAMES.home} className={styles.headings}>
        <div>
          <motion.h1 className={styles.title}
          initial={{y: 50, opacity: 0}}
          animate={{y: 0, opacity: 1}}
          transition={{delay: 0.3, duration: .3}}
          >404</motion.h1>
          <motion.p className={styles.desc}
          initial={{y: 50, opacity: 0}}
          animate={{y: 0, opacity: 1}}
          transition={{delay: 0.3, duration: .6}}
          >that’s incorrect link, buddy <span>✦</span></motion.p>
        </div>
      </Link>
    </div>
  )
}
