export enum DocsEnum {
  TERMS = "terms-conditions",
  OFFER = "offer",
  DETAILS = "details-non-residents",
  SCC = "scc",
  ADO = "terms-conditions-ado",
  TARIFF = "tariff-to-terms-ado",
  SHARING = "data-sharing",
}

export const DOCS_INFO = {
  [DocsEnum.TERMS]: "Terms and conditions for publishers",
  [DocsEnum.OFFER]: "Offer for publishers",
  [DocsEnum.DETAILS]: "Details of the Company for non-residents",
  [DocsEnum.SCC]: "Standard contractual clauses",
  [DocsEnum.ADO]: "Terms and Conditions of Information Exchange with ADO",
  [DocsEnum.TARIFF]: "Tariff to terms and conditions of information exchange with ADO",
  [DocsEnum.SHARING]: `Data Sharing Addendum \n(Controller-Controller)`
}
