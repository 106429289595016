import Layout from 'pages/Layout'
import ModalContextProvider from 'shared/contexts/modal-context'
import MusicContextProvider from 'shared/contexts/music-context'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  Routes
} from "react-router-dom";
import { ROUTERS_NAMES } from "./shared/consts";
import HomePage from "./pages/HomePage";
import WatchDocsPage from "./pages/WatchDocsPage";
import ErrorPage from "./pages/ErrorPage";

function RouteWrapper() {
  return (
    <Routes>
      <Route path={ROUTERS_NAMES.home} element={<Layout/>}>
        <Route index element={<HomePage/>}/>,
        <Route path={`${ROUTERS_NAMES.watchDocs}/:docName`} element={<WatchDocsPage/>}/>,
        <Route path={ROUTERS_NAMES.notFound} element={<ErrorPage/>}/>,
        <Route path="*" element={<Navigate to={ROUTERS_NAMES.notFound}/>}/>
      </Route>
    </Routes>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<RouteWrapper/>}/>)
);

export default function App() {
  return (
    <ModalContextProvider>
      <MusicContextProvider>
        <RouterProvider router={router}/>
      </MusicContextProvider>
    </ModalContextProvider>
  );
}
