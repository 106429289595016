import styles from './Modal.module.scss'
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { motion } from 'framer-motion'
import { ModalContext } from "shared/contexts";

enum InputEnum {
  "NAME",
  "EMAIL",
  "MESSAGE"
}

type InputListType = [React.RefObject<HTMLInputElement>, React.RefObject<HTMLInputElement>, React.RefObject<HTMLTextAreaElement>]

const INITIAL_VALUES = {
  name: '',
  email: '',
  message: ''
}

const INITIAL_ERRORS = {
  name: false,
  email: false,
  message: false
}

export default function Modal() {
  const inputRefs: InputListType = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLTextAreaElement>(null)
  ]

  const { modalOpened: opened, setModalOpened: onClose } = useContext(ModalContext)

  const [formData, setFormData] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState(INITIAL_ERRORS);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState(false);
  const [activeIndex, setActiveIndex] = useState(InputEnum.NAME)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const inputHasError = (name: string, value: string) => {
    switch (name) {
      case 'name':
        return value.length < 2;
      case 'email':
        return !/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/g.test(value);
      default:
        return false
    }
  }

  const handleErrors = (name: string, value: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: inputHasError(name, value),
    }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: inputHasError(name, value),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!opened || response) return
    const botId = "7494965293:AAHLhnPrxiR-756CvUa2GAlEU1tnUDh08Rw"
    const chatId = "5112461814"

    setIsSubmitting(true);

    try {
      let message = '*NEW APPLICATION🔥*\n\n'
      message += `*name:* ${formData.name}\n`
      message += `*email:* ${formData.email}\n`
      message += `*message:* ${formData.message}\n\n`
      message += `_Process faster⏳_`

      for (const [name, value] of Object.entries(formData)) {
        handleErrors(name, value)
      }

      for (const value of Object.values(errors)) {
        if (value) throw new Error('Some inputs has errors')
      }

      const response = await fetch(`https://api.telegram.org/bot${botId}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}&parse_mode=markdown`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const result = await response.json();
      setResponse(result);
    } catch (error) {
      // console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Good inputs
  const handleFocus = (index: InputEnum) => () => {
    setActiveIndex(index);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'ArrowDown' || e.key === 'Tab') {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
    } else if (e.key === 'ArrowUp') {
      setActiveIndex((prevIndex) => (prevIndex + 2) % 3);
    }
  }

  const handleClose = useCallback(() => {
    onClose(false)
    if (!isSubmitting) {
      setFormData(INITIAL_VALUES)
      setErrors(INITIAL_ERRORS)
    }
  }, [isSubmitting, onClose])

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        handleClose()
      }
    }

    window.addEventListener('keyup', keyListener)

    return () => {
      window.removeEventListener('keyup', keyListener)
    }
  }, [handleClose])

  useEffect(() => {
    if (opened && inputRefs[activeIndex].current) {
      inputRefs[activeIndex].current?.focus();
    }
  }, [activeIndex, opened]);

  return (
    <motion.div className={styles.modal + " " + (opened ? styles.modalOpened : "")}>
      <div
        onClick={() => {
          onClose(false)
        }}
        className={styles.modalClose}
      >
        close
      </div>
      <div className={styles.modalInner}>
        <h2 className={styles.modalTitle1}>Get <br/> in <br/> touch</h2>
        <form onSubmit={handleSubmit} onKeyDown={handleKeyDown} className={styles.modalForm}>
          <input
            ref={inputRefs[0]}
            placeholder="your name"
            className={styles.modalInput + " " + (errors.name ? styles.modalInputError : "")}
            type="text"
            id="name"
            name="name"
            value={formData.name}
            disabled={response}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={handleFocus(InputEnum.NAME)}
          />
          <input
            ref={inputRefs[1]}
            placeholder="email"
            className={styles.modalInput + " " + (errors.email ? styles.modalInputError : "")}
            type="email"
            disabled={response}
            id="email"
            name="email"
            // formNoValidate
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={handleFocus(InputEnum.EMAIL)}
          />
          <textarea
            ref={inputRefs[2]}
            placeholder="message"
            style={{ resize: "none" }}
            className={styles.modalTextarea + " " + (errors.message ? styles.modalInputError : "")}
            id="message"
            name="message"
            disabled={response}
            value={formData.message}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={handleFocus(InputEnum.MESSAGE)}
          />
          <button
            className={styles.modalButton}
            disabled={response}
            style={{ backgroundColor: response ? "#bababa" : "" }}
          >
            {!response && !isSubmitting ? 'Get in touch' : ''}
            {isSubmitting && !response ? 'Submitting...' : ''}
            {response && !isSubmitting ? 'Done...' : ''}
          </button>
        </form>
        {response && <h2 className={styles.modalTitle2}>Thank <br/> you</h2>}
      </div>
    </motion.div>
  )
}
