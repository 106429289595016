import {
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from 'react'
import musicSource from 'shared/assets/music/website-music.mp3'

interface IMusicContext {
  musicPlaying: boolean,
  toggleMusic: () => void
}

const DEFAULT_STATE: IMusicContext = {
  musicPlaying: false,
  toggleMusic: () => {
  }
}

export const MusicContext = createContext<IMusicContext>(DEFAULT_STATE)

export default function MusicContextProvider({ children }: PropsWithChildren) {
  const [musicPlaying, setMusicPlaying] = useState(DEFAULT_STATE.musicPlaying)
  const audioRef = useRef<HTMLAudioElement>(null);

  const toggleMusic = () => {
    if (audioRef.current) {
      if (!audioRef.current.paused) {
        audioRef.current.pause()
        setMusicPlaying(false)
      } else {
        audioRef.current.volume = 0.1;
        audioRef.current.currentTime = 0
        audioRef.current.play()
        setMusicPlaying(true)
      }
    }
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.1;
      audioRef.current.onended = audioRef.current?.play
    }
  }, [])

  return (
    <MusicContext.Provider value={{ musicPlaying, toggleMusic }}>
      <audio ref={audioRef} src={musicSource}/>
      {children}
    </MusicContext.Provider>
  )
}
